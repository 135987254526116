import { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import axios from "axios";

const Proveedor = ({ handleChange, formData, emptyProveedor }) => {
  const [listaProveedores, setListaProveedores] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const getProveedores = async () => {
    try {
      const res = await axios(`${initialENDPOINT}/proveedor?cantidad=999`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });

      isMounted && setListaProveedores(res.data.data.original.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    isMounted && getProveedores();
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <Col className="col-12 col-xl-6 h-100">
      <div className="bg-white p-4 border rounded" style={{ height: "100%" }}>
        <h6 className="pb-3">Proveedor</h6>
        <Form.Group className="d-flex align-items-end mb-3">
          <Form.Label className="col-4 col-md-4">Proveedor</Form.Label>
          <Form.Select
            value={formData.proveedor || 0}
            name="proveedor"
            onChange={handleChange}
            className={emptyProveedor ? `is-invalid` : ""}
          >
            <option value={0}>Seleccione</option>
            {listaProveedores?.map((item, i) => (
              <option key={i} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="d-flex align-items-end mb-3">
          <Form.Label className="col-4 col-md-4">Fecha de ingreso</Form.Label>
          <Form.Control
            type="date"
            name="fechaDeIngreso"
            onChange={handleChange}
            defaultValue={formData.fechaDeIngreso?.slice(0, 10)}
            required
          />
        </Form.Group>
        <Form.Group className="d-flex align-items-end mb-3">
          <Form.Label className="col-4 col-md-4">Fecha de pago</Form.Label>
          <Form.Control
            type="date"
            name="fechaDePago"
            onChange={handleChange}
            defaultValue={formData.fechaDePago?.slice(0, 10)}
            required
          />
        </Form.Group>
        <Form.Group className="d-flex align-items-end mb-3">
          <Form.Label className="col-4 col-md-4">N° de lote</Form.Label>
          <Form.Control
            type="text"
            name="numeroLote"
            onChange={handleChange}
            defaultValue={formData.numeroLote?.slice(0, 10)}
          />
        </Form.Group>
        <Form.Group className="d-flex align-items-center mb-3">
          <Form.Label className="col-4 col-md-4">Pagado {formData.pagado ? 'Sí' : 'No'}</Form.Label>
          <Form.Check
            type="switch"
            id="pagado"
            name="pagado"
            onChange={handleChange}
            checked={formData.pagado}
          />
        </Form.Group>
      </div>
    </Col>
  );
};

export default Proveedor;
