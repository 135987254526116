import { useEffect, useState } from "react";
import { Form, Row, Button, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Breadcrumbs } from "../../../../AbstractElements";
import Proveedor from "./Proveedor";
import Gastos from "./Gastos";
import Productos from "./Productos";

const TemplatePurchase = () => {
  let { purchaseID } = useParams();
  const history = useNavigate();
  const [formData, setFormData] = useState({
    proveedor: "",
    fechaDeIngreso: "",
    fechaDePago: "",
    numeroLote: "",
    detalle: "",
    precio: 0,
    precioGasto: 0,
    observaciones: "",
    pagado: false,
    gastos: [],
    productos: [],
    idProducto: "",
    nombreProducto: "",
    precioProducto: 0,
    cantidadProducto: "",
    cantidadDeArticulos: 0,
    cantidadDeUnidades: 0,
  });
  const [disableButton, setDisableButton] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [emptyProveedor, setEmptyProveedor] = useState();
  const [emptyProductos, setEmptyProductos] = useState();
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const notify = () =>
    toast(purchaseID != 0 ? "Compra editada!" : "Compra creada!");

  const handleChange = (e) => {
    setEmptyProveedor(false);
    setEmptyProductos(false);
    let value;
    if (e.target.type === "checkbox") {
      value = e.target.checked; // El valor correcto para checkbox
    } else {
      value = e.target.value; // Para otros tipos de entrada
    }
    
    let name = e.target.name;
    // e.target.type === "checkbox"
    //   ? (value = e.target.checked)
    //   : (value = e.target.value);
    // let name = e.target.name;

    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  async function getData(id) {
    try {
      const response = await axios({
        url: `${initialENDPOINT}/compra/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },

        redirect: "follow",
      });
      isMounted && setFormData(response.data.data[0]);
      // history(`${process.env.PUBLIC_URL}/compras`);
    } catch (error) {
      console.log(error);
    }
  }

  const sendForm = async (e) => {

    e.preventDefault();
    // setDisableButton(true);

    if (formData.proveedor === "") {
      setEmptyProveedor(true);
    //} else if (formData.productos?.length === 0) {
      setEmptyProductos(true);
    } else {
      // const dataToSend = JSON.stringify({
      const dataToSend = {
        proveedor: formData.proveedor,
        fechaDeIngreso: formData.fechaDeIngreso,
        fechaDePago: formData.fechaDePago,
        // precio: formData.subtotalGastos,
        precioVenta: formData.precioVenta,
        precio: 110,
        numeroLote: formData.numeroLote ? formData.numeroLote : 0,
        observaciones: formData.observaciones,
        pagado: formData.pagado ? 1 : 0,
        enDeposito: formData.enDeposito,
        productos: formData.productos,
        gastos: formData.gastos,
      };

      try {
        let resp = await axios({
          url: `${initialENDPOINT}/compra${
            purchaseID != 0 ? `/${purchaseID}` : ""
          }`,
          method: `${purchaseID != 0 ? "PUT" : "POST"}`,
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
          data: dataToSend,
          redirect: "follow",
        });
        notify();
        history(`${process.env.PUBLIC_URL}/compras`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    isMounted && purchaseID != 0 && getData(purchaseID);
    return () => {
      setIsMounted(false);
    };
  }, [purchaseID]);

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle={
          purchaseID == 0 ? "Nueva compra" : `Editar compra: ${purchaseID}`
        }
        parent="Compras"
        title={purchaseID == 0 ? "Nueva compra" : `Editar compra`}
      />
      <Form onSubmit={sendForm}>
        <Row className="g-3">
          <Proveedor
            emptyProveedor={emptyProveedor}
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
          />
          <Gastos
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
          />
          <Productos
            emptyProductos={emptyProductos}
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
          />
        </Row>
        <Row className="d-flex justify-content-end px-3">
          <Button
            disabled={disableButton}
            type="submit"
            className="mb-5 mt-2 col-2 col-lg-1 col-sm-2"
          >
            Guardar
          </Button>
        </Row>
      </Form>
    </Container>
  );
};

export default TemplatePurchase;
