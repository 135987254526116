import GaleryContainer from "./GaleryContainer";
import { useEffect, useState } from "react";
import { Form, Image, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

const EditProductImages = ({ formData, setFormData, getData }) => {
  const [newImages, setNewImages] = useState(false);
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const notify = () => toast("Imagenes cargadas!");

  const sendData = async () => {
    const dataToSend = new FormData();
    dataToSend.append("id", formData.id);
    dataToSend.append("action", "insertar");
    {
      formData.imagenesParaEnviar !== undefined &&
        formData.imagenesParaEnviar.map((item) => {
          dataToSend.append("images[]", item);
        });
    }

    try {
      const response = await axios({
        url: `${initialENDPOINT}/fotoproducto`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });
    } catch (error) {
      console.log(error);
    } finally {
      notify();
    }
  };

  const onImageChange = (event) => {
    let name = event.target.name;

    if (name === "imagenes" && event.target.files && event.target.files[0]) {
      setFormData((prevalue) => {
        const arr = formData.imagenes;
        const arrToSend = [];
        for (let i = 0; i < event.target.files.length; i++) {
          arr.push({
            id: event.target.files[i].lastModified,
            url: URL.createObjectURL(event.target.files[i]),
          });
        }
        for (let i = 0; i < event.target.files.length; i++) {
          arrToSend.push(event.target.files[i]);
        }
        return {
          ...prevalue,
          imagenes: arr,
          imagenesParaEnviar: arrToSend,
        };
      });
      setNewImages(true);
    }
  };

  useEffect(() => {
    newImages && sendData();
    setNewImages(false);
  }, [newImages]);

  return (
    <>
      {/* Imagen principal */}
      <Form.Group>
        <div
          className={`btn btn-rounded p-0 w-100 pt-3 ${
            !formData.imagenes && "btn-primary px-3 py-2"
          }`}
        >
          <label
            style={{ cursor: "pointer", color: "white" }}
            htmlFor="imageOLD"
          >
            {/* {console.log("formData", formData)} */}
            <Image
              //src={`https://mayoristasdeopticas.net/productos/${
                src={formData?.imagenPrincipal}
              alt={formData.nombre}
              title="Imagen destacada 0"
              style={{ width: "100%", marginBottom: 20 }}
            />
          </label>
        </div>
      </Form.Group>
      {/* Lista de imágenes */}
      <Row>
        <GaleryContainer formData={formData} getData={getData} />
      </Row>
      <div className="d-flex justify-content-between align-items-center ">
        <label
          className="btn btn-primary px-3 py-2 mt-3 mx-auto"
          htmlFor="image"
        >
          <p size="sm">Agregar imagenes</p>
          <input
            type="file"
            onChange={onImageChange}
            className="form-control d-none filetype"
            id="image"
            name="imagenes"
            multiple
          />
        </label>
      </div>
    </>
  );
};

export default EditProductImages;
