import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { downloadCSV, Export } from "../../../_helper/hooks/useExportCsv";
import DataTable from "react-data-table-component";
import Loader from "../../Common/Loader";
import Vacio from "../../Common/Vacio";
import TableTitle from "../../Common/TableTitle";

const ProductsTable = ({
  data,
  errMsg,
  loading,
  totalRows,
  perPage,
  setPerPage,
  setPage,
  setENDPOINT,
  filterENDPOINT,
  handleShowCancellations,
  handleProductoDelete,
}) => {
  const imagenes = process.env.IMAGE_APP_IMAGENES;
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const queriesColumns = [
    {
      name: "",
      selector: (row) => (
        <img
          //src={`https://mayoristasdeopticas.net/productos/${
            src={
            row?.imagenPrincipal
          }
          width="80%"
          alt={row.nombre}
        ></img>
      ),
      center: false,
      wrap: true,
      grow: 0,
    },
    {
      name: "Id",
      selector: (row) => row.id,
      center: true,
      wrap: true,
      compact: true,
    },
    {
      name: "Código",
      selector: (row) => (row.codigo ? row.codigo : "Sin código"),
      center: false,
      wrap: true,
      compact: true,
    },
    {
      name: "Marca",
      selector: (row) => `${row.nombreMarca}`,
      center: false,
      compact: false,
      wrap: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      center: false,
      wrap: true,
      grow: 2,
    },
    {
      name: "Categoría",
      selector: (row) =>
        row.categoria ? row.categoriaNombre : "Sin categoría",
      center: false,
      hide: "sm",
      wrap: true,
      compact: true,
    },
    {
      name: "Precio",
      selector: (row) => `U$D ${row.precio}`,
      center: false,
      compact: false,
      wrap: true,
    },
    {
      name: "Stock",
      selector: (row) => row.stock,
      center: false,
      hide: "sm",
      wrap: true,
      compact: true,
    },
    {
      name: "Estado",
      selector: (row) => (
        <Badge bg={row.estado === "Suspendido" ? "danger" : "primary"}>
          {row.estado === "Suspendido" ? "Suspendido" : "Publicado"}
        </Badge>
      ),
      center: false,
      compact: true,
    },
    {
      name: "Acciones",
      selector: (row) => [
        <Link key={1} to={`/editar-producto/${row.id}`}>
          <button
            key={2}
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Editar"
          >
            <i className="fa fa-pencil-square-o f-16 px-1" />
          </button>
        </Link>,
        ,
        <button
          key={3}
          className="btn text-dark w-auto p-1 mx-1 my-0"
          type="button"
          title="Borrar"
          onClick={() => handleProductoDelete({ id: row.id })}
        >
          <i className="fa fa-trash f-16 px-1" />
        </button>
      ],
      center: true,
      hide: "md",
      compact: true,
    },
  ];

  const handlePageChange = (pag) => {
    setENDPOINT(
      `${initialENDPOINT}/producto?pagina=${pag}&cantidad=${perPage}${filterENDPOINT}`
    );
  };

  const handlePerRowsChange = async (newPerPage, pag) => {
    setPerPage(newPerPage);
    setPage(pag);

    await setENDPOINT(
      `${initialENDPOINT}/producto?pagina=${pag}&cantidad=${newPerPage}${filterENDPOINT}`
    );
  };

  const actionsMemo = useMemo(
    () => (
      <Export
        onExport={() => downloadCSV(data, "Consultas")}
        cancallations={handleShowCancellations}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <div
      className="App mb-5 table-queries border rounded "
      class="overflow-hidden overflow-x-auto"
      // style={{ overflow: "hidden", overflowX: "auto" }}
    >
      {/* {errMsg && <p>{errMsg}</p>} */}
      {/* {console.log("data", data)} */}
      <DataTable
        title={<TableTitle value="Lista de productos" />}
        columns={queriesColumns}
        data={data}
        striped
        customStyles={{
          rows: {
            highlightOnHoverStyle: {
              backgroundColor: "rgb(230, 244, 244)",
              borderBottomColor: "#FFFFFF",
              outline: "1px solid #FFFFFF",
            },
          },
          pagination: {
            style: {
              border: "none",
            },
          },
        }}
        highlightOnHover=""
        progressPending={loading}
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        pagination
        paginationServer
        paginationComponentOptions={{
          rowsPerPageText: "Filas por página",
          rangeSeparatorText: "de",
        }}
        paginationDefaultPage
        paginationTotalRows={totalRows}
        contextMessage={{
          singular: "",
          plural: "",
          message: "",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        actions={actionsMemo}
        persistTableHead
        handleProductoDelete={handleProductoDelete}
      />
    </div>
  );
};

export default ProductsTable;
