import DataTable from "react-data-table-component";
import { Form, InputGroup } from "react-bootstrap";
import { useEffect } from "react";

const Table = ({ formData, setFormData, handleProductDelete }) => {
  const queriesColumns = [
    {
      name: "Descripcion",
      selector: (row) => row.descripcion,
      center: false,
      wrap: false,
    },
    {
      name: "Precio",
      selector: (row) => `USD ${row.precioGasto}`,
      center: false,
      compact: true,
      wrap: false,
    },
    {
      name: "Acciones",
      selector: (row) => [
        <button
          key={3}
          className="btn text-dark w-auto p-1 mx-1 my-0"
          type="button"
          title="Borrar"
          onClick={() => handleProductDelete(row)}
        >
          <i className="fa fa-trash f-16 px-1" />
        </button>,
      ],
      center: true,
      hide: "md",
      compact: true,
    },
  ];

  const subtotalGastos = (array) => {
    return array?.reduce((acc, curr) => acc + parseFloat(curr.precioGasto), 0);
  };

  const calcularTotales = () => {
    setFormData((prevFormData) => {
      const gastos = prevFormData.gastos;
      const subtotal = subtotalGastos(gastos)?.toFixed(2);
      return {
        ...prevFormData,
        subtotalGastos: subtotal,
      };
    });
  };

  useEffect(() => {
    calcularTotales();
  }, [formData.gastos]);

  return (
    <div className="my-3 table-queries" style={{ overflow: "hidden" }}>
      {formData.gastos.length > 0 && (
        <>
          <DataTable
            columns={queriesColumns}
            data={formData.gastos}
            striped
            customStyles={{
              rows: {
                highlightOnHoverStyle: {
                  backgroundColor: "rgb(230, 244, 244)",
                  borderBottomColor: "#FFFFFF",
                  outline: "1px solid #FFFFFF",
                },
              },
              pagination: {
                style: {
                  border: "none",
                },
              },
            }}
            pointerOnHover
            highlightOnHover
            persistTableHead
          />
          <InputGroup className="mt-3 w-50 ms-auto">
            <InputGroup.Text>Total USD</InputGroup.Text>
            <Form.Control
              aria-label="Total"
              value={formData.subtotalGastos}
              readOnly
              className="text-end"
            />
          </InputGroup>
        </>
      )}
    </div>
  );
};

export default Table;
